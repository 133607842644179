.react-chatbot-kit-error {
  background-color: #fff;
  border-radius: 3px;
  padding: 15px;
}

.react-chatbot-kit-error-container {
  width: 260px;
}

.react-chatbot-kit-error-header {
  font-size: 1.3rem;
  color: #1d1d1d;
  margin-bottom: 30px;
}

.react-chatbot-kit-error-docs {
  display: block;
  margin: 25px auto;
  color: rgb(56, 104, 139);
  padding: 8px;
  border: 1px solid rgb(40, 152, 236);
  width: 130px;
  text-align: center;
  text-decoration: none;
  font-size: 1rem;
}
