.lead-form-message {
    background-color: #7cbbeb;
    padding: 10px;
    border-radius: 5px;
    font-size: 0.9rem;
    color: #fff;
    font-weight: medium;
    position: relative;
    width: auto;
    width: 90%;
    max-width: 300px;
    margin-left: 0;
    text-align: left;
}

.lead-input-form{
    display: flex;
    flex-direction: column;
}

.form-label{
    font-size: medium;
    /* padding-top: 10px; */
    margin-top: 10px;
}

.form-input{
    display: block;
    color: rgb(103, 117, 124);
    min-height: 18px;
    width: -webkit-fill-available;
    display: block;
    font-size: 1rem;
    line-height: 1.5;
    color: rgb(73, 80, 87);
    background-color: rgb(255, 255, 255);
    background-clip: padding-box;
    padding: 0.375rem 0.75rem;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(206, 212, 218);
    border-image: initial;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
    margin-top: 5px;
    margin-bottom: 10px;
}

.form-btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space-collapse: collapse;
    text-wrap: nowrap;
    vertical-align: middle;
    user-select: none;
    font-size: 1rem;
    line-height: 1.5;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    padding: 0.375rem 0.75rem;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
}

.lead-form-saved-contact-banner{
    color: rgb(43, 247, 43)
}