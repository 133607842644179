.react-chatbot-kit-chat-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.react-chatbot-kit-chat-inner-container {
  height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.react-chatbot-kit-chat-header {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  background-color: #efefef;
  font-family: Arial;
  display: flex;
  align-items: center;
  font-size: 0.85rem;
  color: #514f4f;
  padding: 12.5px;
  font-weight: bold;
  max-height: 50px;
}

.react-chatbot-kit-bot-avatar-container{
  max-height: inherit;
}

.react-chatbot-kit-bot-avatar{
  max-height: inherit;
  border-radius: 30%;
}

.chatbot-header-spacer{
  flex-grow: 1;
}

.chatbot-action-container {
  max-height: inherit;
}

.chatbot-language-select{
  padding: 0;
  margin: 0;
  border: 0;
  max-width: 78px;
  height: 22px;
  vertical-align: top;
}

.chatbot-action-button{
  border: 0;
}

.chatbot-action-refresh-icon{
  max-height: 20px;
}

.chatbot-action-close-icon{
  max-height: 20px;
}

.react-chatbot-kit-chat-input-container {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.suggested-messages-container{
  display: flex;
  flex-direction: row;
  overflow: auto;
  box-sizing: inherit;
  border: 0 solid #e5e7eb;
}

.suggested-message-item{
  white-space: nowrap;
  min-height: min-content;
  display: inline-block;
  margin: 5px;
  font-size: .875rem;
  line-height: 1.25rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(244 244 245/var(--tw-bg-opacity));
  border-radius: 0.5rem;
  cursor: pointer;
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

.react-chatbot-kit-chat-message-container {
  padding: 0 17.5px 65px 17.5px;
  overflow: scroll;
  flex-grow: 1;
}

.react-chatbot-kit-chat-input {
  width: 100%;
  padding: 12.5px;
  border: none;
  font-size: 0.85rem;
  border-top: 1px solid #d8d8d8;
  border-bottom-left-radius: 5px;
}

.react-chatbot-kit-chat-input-form {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.react-chatbot-kit-chat-input::placeholder {
  color: #585858;
}

.react-chatbot-kit-chat-btn-send {
  background-color: #2898ec;
  width: 100px;
  border: none;
  color: #fff;
  border-bottom-right-radius: 5px;
}

.react-chatbot-kit-chat-btn-send-icon {
  fill: #fff;
  width: 15px;
  margin: 0 auto;
}
