.react-chatbot-kit-chat-bot-message-container {
  display: flex;
  margin: 15px 0;
  justify-content: flex-start;
}

.react-chatbot-kit-chat-bot-avatar-container {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 1.2rem;
  margin-right: 12.5px;
  background-color: #d8d8d8;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-chatbot-kit-chat-bot-avatar-icon {
  fill: #494646;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.react-chatbot-kit-bot-avatar-icon {
  fill: #fff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}


.react-chatbot-kit-chat-bot-avatar-letter {
  color: #1d1d1d;
  margin: 0;
  padding: 0;
}

.react-chatbot-kit-chat-bot-message {
  background-color: #2898ec;
  padding: 10px;
  border-radius: 5px;
  font-size: 0.9rem;
  color: #fff;
  font-weight: medium;
  position: relative;
  width: auto;
  max-width: 200px;
  margin-left: 0;
  text-align: left;
}

.react-chatbot-kit-chat-bot-message-arrow {
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid #2898ec;
  position: absolute;
  left: -7px;
  top: 13px;
}

.react-chatbot-kit-chat-bot-loading-icon-container {
  height: 17px;
  width: 25px;
}

.chatbot-loader-container {
  display: flex;
  width: 100%;
  justify-content: center;
}

#chatbot-loader #chatbot-loader-dot1 {
  animation: load 1s infinite;
}

#chatbot-loader #chatbot-loader-dot2 {
  animation: load 1s infinite;
  animation-delay: 0.2s;
}

#chatbot-loader #chatbot-loader-dot3 {
  animation: load 1s infinite;
  animation-delay: 0.4s;
}

@keyframes load {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
